@font-face {
  font-family: "wokiee";
  src: url("../font/fonts/wokiee.eot?g7nf10");
  src: url("../font/fonts/wokiee.eot?g7nf10#iefix") format("embedded-opentype"),
  url("../font/fonts/wokiee.ttf?g7nf10") format("truetype"),
  url("../font/fonts/wokiee.woff?g7nf10") format("woff"),
  url("../font/fonts/wokiee.svg?g7nf10#wokiee") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "wokiee", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sale:before {
  content: "\eae1";
}

.icon-theme:before {
  content: "\eae2";
}

.icon-women:before {
  content: "\eae3";
}

.icon-categories:before {
  content: "\eae4";
}

.icon-men:before {
  content: "\eae5";
}

.icon-new:before {
  content: "\eae6";
}

.icon-paypal-22 .path1:before {
  content: "\ea52";
  color: rgb(70, 155, 219);
}

.icon-paypal-22 .path2:before {
  content: "\ea53";
  margin-left: -3.77734375em;
  color: rgb(40, 59, 130);
}

.icon-paypal-22 .path3:before {
  content: "\ea54";
  margin-left: -3.77734375em;
  color: rgb(40, 59, 130);
}

.icon-paypal-22 .path4:before {
  content: "\ea55";
  margin-left: -3.77734375em;
  color: rgb(70, 155, 219);
}

.icon-paypal-22 .path5:before {
  content: "\ea56";
  margin-left: -3.77734375em;
  color: rgb(40, 59, 130);
}

.icon-paypal-22 .path6:before {
  content: "\ea57";
  margin-left: -3.77734375em;
  color: rgb(70, 155, 219);
}

.icon-shopify2 .path1:before {
  content: "\ea62";
  color: rgb(255, 255, 255);
}

.icon-shopify2 .path2:before {
  content: "\ea63";
  margin-left: -3.0185546875em;
  color: rgb(255, 255, 255);
}

.icon-shopify2 .path3:before {
  content: "\ea64";
  margin-left: -3.0185546875em;
  color: rgb(255, 255, 255);
}

.icon-shopify2 .path4:before {
  content: "\ea65";
  margin-left: -3.0185546875em;
  color: rgb(255, 255, 255);
}

.icon-shopify2 .path5:before {
  content: "\ea66";
  margin-left: -3.0185546875em;
  color: rgb(255, 255, 255);
}

.icon-shopify2 .path6:before {
  content: "\ea67";
  margin-left: -3.0185546875em;
  color: rgb(255, 255, 255);
}

.icon-shopify2 .path7:before {
  content: "\ea68";
  margin-left: -3.0185546875em;
  color: rgb(255, 255, 255);
}

.icon-shopify2 .path8:before {
  content: "\ea69";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify2 .path9:before {
  content: "\ea6a";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify2 .path10:before {
  content: "\ea6b";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify2 .path11:before {
  content: "\ea6c";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify2 .path12:before {
  content: "\ea6d";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify2 .path13:before {
  content: "\ea6e";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify2 .path14:before {
  content: "\ea6f";
  margin-left: -3.0185546875em;
  color: rgb(148, 192, 68);
}

.icon-Stripe2 .path1:before {
  content: "\ea70";
  color: rgb(103, 114, 229);
}

.icon-Stripe2 .path2:before {
  content: "\ea71";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path3:before {
  content: "\ea72";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path4:before {
  content: "\ea73";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path5:before {
  content: "\ea74";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path6:before {
  content: "\ea75";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path7:before {
  content: "\ea76";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path8:before {
  content: "\ea77";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path9:before {
  content: "\ea78";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path10:before {
  content: "\ea79";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path11:before {
  content: "\ea7a";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe2 .path12:before {
  content: "\ea7b";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-visa2 .path1:before {
  content: "\ea7c";
  color: rgb(0, 93, 172);
}

.icon-visa2 .path2:before {
  content: "\ea7d";
  margin-left: -3.22265625em;
  color: rgb(0, 93, 172);
}

.icon-visa2 .path3:before {
  content: "\ea7e";
  margin-left: -3.22265625em;
  color: rgb(0, 93, 172);
}

.icon-visa2 .path4:before {
  content: "\ea7f";
  margin-left: -3.22265625em;
  color: rgb(0, 93, 172);
}

.icon-visa2 .path5:before {
  content: "\ea80";
  margin-left: -3.22265625em;
  color: rgb(251, 177, 32);
}

.icon-american-express2 .path1:before {
  content: "\eae9";
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path2:before {
  content: "\eaea";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path3:before {
  content: "\eaeb";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path4:before {
  content: "\eaec";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path5:before {
  content: "\eaed";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path6:before {
  content: "\eaee";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path7:before {
  content: "\eaef";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path8:before {
  content: "\eaf0";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path9:before {
  content: "\eaf1";
  margin-left: -2.9443359375em;
  color: rgb(34, 143, 224);
}

.icon-american-express2 .path10:before {
  content: "\eaf2";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express2 .path11:before {
  content: "\eaf3";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-discover2 .path1:before {
  content: "\eaf4";
  color: rgb(245, 130, 30);
}

.icon-discover2 .path2:before {
  content: "\eaf5";
  margin-left: -3.1640625em;
  color: rgb(255, 255, 255);
}

.icon-discover2 .path3:before {
  content: "\eaf6";
  margin-left: -3.1640625em;
  color: rgb(255, 255, 255);
}

.icon-discover2 .path4:before {
  content: "\eaf7";
  margin-left: -3.1640625em;
  color: rgb(255, 255, 255);
}

.icon-discover2 .path5:before {
  content: "\eaf8";
  margin-left: -3.1640625em;
  color: rgb(255, 255, 255);
}

.icon-discover2 .path6:before {
  content: "\eaf9";
  margin-left: -3.1640625em;
  color: rgb(255, 255, 255);
}

.icon-discover2 .path7:before {
  content: "\eafa";
  margin-left: -3.1640625em;
  color: rgb(255, 255, 255);
}

.icon-discover2 .path8:before {
  content: "\eafb";
  margin-left: -3.1640625em;
  color: rgb(255, 255, 255);
}

.icon-discover2 .path9:before {
  content: "\eafc";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover2 .path10:before {
  content: "\eafd";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover2 .path11:before {
  content: "\eafe";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover2 .path12:before {
  content: "\eaff";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover2 .path13:before {
  content: "\eb00";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover2 .path14:before {
  content: "\eb01";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover2 .path15:before {
  content: "\eb02";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-mastercard2 .path1:before {
  content: "\eb03";
  color: rgb(234, 25, 42);
}

.icon-mastercard2 .path2:before {
  content: "\eb04";
  margin-left: -1.6728515625em;
  color: rgb(249, 181, 50);
}

.icon-mastercard2 .path3:before {
  content: "\eb05";
  margin-left: -1.6728515625em;
  color: rgb(234, 25, 42);
}

.icon-mastercard2 .path4:before {
  content: "\eb06";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path5:before {
  content: "\eb07";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path6:before {
  content: "\eb08";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path7:before {
  content: "\eb09";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path8:before {
  content: "\eb0a";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path9:before {
  content: "\eb0b";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path10:before {
  content: "\eb0c";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path11:before {
  content: "\eb0d";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path12:before {
  content: "\eb0e";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard2 .path13:before {
  content: "\eb0f";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path1:before {
  content: "\ea84";
  color: rgb(234, 25, 42);
}

.icon-mastercard .path2:before {
  content: "\ea85";
  margin-left: -1.6728515625em;
  color: rgb(249, 181, 50);
}

.icon-mastercard .path3:before {
  content: "\ea86";
  margin-left: -1.6728515625em;
  color: rgb(234, 25, 42);
}

.icon-mastercard .path4:before {
  content: "\ea87";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path5:before {
  content: "\ea88";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path6:before {
  content: "\ea89";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path7:before {
  content: "\ea8a";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path8:before {
  content: "\ea8b";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path9:before {
  content: "\ea8c";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path10:before {
  content: "\ea8d";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path11:before {
  content: "\ea8e";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path12:before {
  content: "\ea8f";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-mastercard .path13:before {
  content: "\ea90";
  margin-left: -1.6728515625em;
  color: rgb(255, 255, 255);
}

.icon-paypal-2 .path1:before {
  content: "\ea91";
  color: rgb(70, 155, 219);
}

.icon-paypal-2 .path2:before {
  content: "\ea92";
  margin-left: -3.77734375em;
  color: rgb(40, 59, 130);
}

.icon-paypal-2 .path3:before {
  content: "\ea93";
  margin-left: -3.77734375em;
  color: rgb(40, 59, 130);
}

.icon-paypal-2 .path4:before {
  content: "\ea94";
  margin-left: -3.77734375em;
  color: rgb(70, 155, 219);
}

.icon-paypal-2 .path5:before {
  content: "\ea95";
  margin-left: -3.77734375em;
  color: rgb(40, 59, 130);
}

.icon-paypal-2 .path6:before {
  content: "\ea96";
  margin-left: -3.77734375em;
  color: rgb(70, 155, 219);
}

.icon-shopify .path1:before {
  content: "\ea97";
  color: rgb(20, 20, 20);
}

.icon-shopify .path2:before {
  content: "\ea98";
  margin-left: -3.0185546875em;
  color: rgb(20, 20, 20);
}

.icon-shopify .path3:before {
  content: "\ea99";
  margin-left: -3.0185546875em;
  color: rgb(20, 20, 20);
}

.icon-shopify .path4:before {
  content: "\ea9a";
  margin-left: -3.0185546875em;
  color: rgb(20, 20, 20);
}

.icon-shopify .path5:before {
  content: "\ea9b";
  margin-left: -3.0185546875em;
  color: rgb(20, 20, 20);
}

.icon-shopify .path6:before {
  content: "\ea9c";
  margin-left: -3.0185546875em;
  color: rgb(20, 20, 20);
}

.icon-shopify .path7:before {
  content: "\ea9d";
  margin-left: -3.0185546875em;
  color: rgb(20, 20, 20);
}

.icon-shopify .path8:before {
  content: "\ea9e";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify .path9:before {
  content: "\ea9f";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify .path10:before {
  content: "\eaa0";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify .path11:before {
  content: "\eaa1";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify .path12:before {
  content: "\eaa2";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify .path13:before {
  content: "\eaa3";
  margin-left: -3.0185546875em;
  color: rgb(133, 133, 133);
}

.icon-shopify .path14:before {
  content: "\eaa4";
  margin-left: -3.0185546875em;
  color: rgb(148, 192, 68);
}

.icon-Stripe .path1:before {
  content: "\eaa5";
  color: rgb(103, 114, 229);
}

.icon-Stripe .path2:before {
  content: "\eaa6";
  margin-left: -2.7041015625em;
  color: rgb(255, 255, 255);
}

.icon-Stripe .path3:before {
  content: "\eaa7";
  margin-left: -2.7041015625em;
  color: rgb(20, 20, 20);
}

.icon-Stripe .path4:before {
  content: "\eaa8";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path5:before {
  content: "\eaa9";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path6:before {
  content: "\eaaa";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path7:before {
  content: "\eaab";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path8:before {
  content: "\eaac";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path9:before {
  content: "\eaad";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path10:before {
  content: "\eaae";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path11:before {
  content: "\eaaf";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-Stripe .path12:before {
  content: "\eab0";
  margin-left: -2.7041015625em;
  color: rgb(0, 0, 0);
}

.icon-visa .path1:before {
  content: "\eab1";
  color: rgb(0, 93, 172);
}

.icon-visa .path2:before {
  content: "\eab2";
  margin-left: -3.22265625em;
  color: rgb(0, 93, 172);
}

.icon-visa .path3:before {
  content: "\eab3";
  margin-left: -3.22265625em;
  color: rgb(0, 93, 172);
}

.icon-visa .path4:before {
  content: "\eab4";
  margin-left: -3.22265625em;
  color: rgb(0, 93, 172);
}

.icon-visa .path5:before {
  content: "\eab5";
  margin-left: -3.22265625em;
  color: rgb(251, 177, 32);
}

.icon-AES256 .path1:before {
  content: "\eab6";
  color: rgb(40, 121, 254);
}

.icon-AES256 .path2:before {
  content: "\eab7";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path3:before {
  content: "\eab8";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path4:before {
  content: "\eabb";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path5:before {
  content: "\eabc";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path6:before {
  content: "\eabd";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path7:before {
  content: "\eabe";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path8:before {
  content: "\eabf";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path9:before {
  content: "\eac0";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path10:before {
  content: "\eac1";
  margin-left: -1.9072265625em;
  color: rgb(20, 20, 20);
}

.icon-AES256 .path11:before {
  content: "\eac2";
  margin-left: -1.9072265625em;
  color: rgb(40, 121, 254);
}

.icon-american-express .path1:before {
  content: "\eac3";
  color: rgb(1, 122, 171);
}

.icon-american-express .path2:before {
  content: "\eac4";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path3:before {
  content: "\eac5";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path4:before {
  content: "\eac6";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path5:before {
  content: "\eac7";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path6:before {
  content: "\eac8";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path7:before {
  content: "\eac9";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path8:before {
  content: "\eaca";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path9:before {
  content: "\eacb";
  margin-left: -2.9443359375em;
  color: rgb(34, 143, 224);
}

.icon-american-express .path10:before {
  content: "\eacc";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-american-express .path11:before {
  content: "\eacd";
  margin-left: -2.9443359375em;
  color: rgb(1, 122, 171);
}

.icon-discover .path1:before {
  content: "\eace";
  color: rgb(245, 130, 30);
}

.icon-discover .path2:before {
  content: "\eacf";
  margin-left: -3.1640625em;
  color: rgb(20, 20, 20);
}

.icon-discover .path3:before {
  content: "\ead0";
  margin-left: -3.1640625em;
  color: rgb(20, 20, 20);
}

.icon-discover .path4:before {
  content: "\ead1";
  margin-left: -3.1640625em;
  color: rgb(20, 20, 20);
}

.icon-discover .path5:before {
  content: "\ead2";
  margin-left: -3.1640625em;
  color: rgb(20, 20, 20);
}

.icon-discover .path6:before {
  content: "\ead3";
  margin-left: -3.1640625em;
  color: rgb(20, 20, 20);
}

.icon-discover .path7:before {
  content: "\ead4";
  margin-left: -3.1640625em;
  color: rgb(20, 20, 20);
}

.icon-discover .path8:before {
  content: "\ead5";
  margin-left: -3.1640625em;
  color: rgb(77, 77, 77);
}

.icon-discover .path9:before {
  content: "\ead6";
  margin-left: -3.1640625em;
  color: rgb(20, 20, 20);
}

.icon-discover .path10:before {
  content: "\ead7";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover .path11:before {
  content: "\ead8";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover .path12:before {
  content: "\ead9";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover .path13:before {
  content: "\eada";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover .path14:before {
  content: "\eadb";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover .path15:before {
  content: "\eadc";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-discover .path16:before {
  content: "\eadd";
  margin-left: -3.1640625em;
  color: rgb(133, 133, 133);
}

.icon-03:before {
  content: "\ea81";
}

.icon-01:before {
  content: "\ea82";
}

.icon-02:before {
  content: "\ea83";
}

.icon-n-07:before {
  content: "\ea61";
}

.icon-n-02:before {
  content: "\ea5e";
}

.icon-n-05:before {
  content: "\ea5f";
}

.icon-n-06:before {
  content: "\ea60";
}

.icon-h-58:before {
  content: "\ea59";
}

.icon-h-59:before {
  content: "\ea5a";
}

.icon-h-60:before {
  content: "\ea5b";
}

.icon-h-61:before {
  content: "\ea5c";
}

.icon-h-62:before {
  content: "\ea5d";
}

.icon-e-01:before {
  content: "\e900";
}

.icon-e-02:before {
  content: "\e901";
}

.icon-e-03:before {
  content: "\e902";
}

.icon-e-04:before {
  content: "\e903";
}

.icon-e-05:before {
  content: "\e904";
}

.icon-e-06:before {
  content: "\e905";
}

.icon-e-07:before {
  content: "\e906";
}

.icon-e-08:before {
  content: "\e907";
}

.icon-e-09:before {
  content: "\e908";
}

.icon-e-10:before {
  content: "\e909";
}

.icon-e-11:before {
  content: "\e90a";
}

.icon-e-12:before {
  content: "\e90b";
}

.icon-e-13:before {
  content: "\e90c";
}

.icon-e-14:before {
  content: "\e90d";
}

.icon-e-15:before {
  content: "\e90e";
}

.icon-e-16:before {
  content: "\e90f";
}

.icon-e-17:before {
  content: "\e910";
}

.icon-e-18:before {
  content: "\e911";
}

.icon-e-19:before {
  content: "\e912";
}

.icon-e-20:before {
  content: "\e913";
}

.icon-e-21:before {
  content: "\e914";
}

.icon-e-22:before {
  content: "\e915";
}

.icon-e-23:before {
  content: "\e916";
}

.icon-e-24:before {
  content: "\e917";
}

.icon-e-25:before {
  content: "\e918";
}

.icon-e-26:before {
  content: "\e919";
}

.icon-e-27:before {
  content: "\e91a";
}

.icon-e-28:before {
  content: "\e91b";
}

.icon-e-29:before {
  content: "\e91c";
}

.icon-e-30:before {
  content: "\e91d";
}

.icon-e-31:before {
  content: "\e91e";
}

.icon-e-32:before {
  content: "\e91f";
}

.icon-e-33:before {
  content: "\e920";
}

.icon-e-34:before {
  content: "\e921";
}

.icon-e-35:before {
  content: "\e922";
}

.icon-e-36:before {
  content: "\e923";
}

.icon-e-37:before {
  content: "\e924";
}

.icon-e-38:before {
  content: "\e925";
}

.icon-e-39:before {
  content: "\e926";
}

.icon-e-40:before {
  content: "\e927";
}

.icon-e-41:before {
  content: "\e928";
}

.icon-e-42:before {
  content: "\e929";
}

.icon-e-43:before {
  content: "\e92a";
}

.icon-e-44:before {
  content: "\e92b";
}

.icon-e-45:before {
  content: "\e92c";
}

.icon-e-46:before {
  content: "\e92d";
}

.icon-e-47:before {
  content: "\e92e";
}

.icon-e-48:before {
  content: "\e92f";
}

.icon-e-49:before {
  content: "\e930";
}

.icon-e-50:before {
  content: "\e931";
}

.icon-e-51:before {
  content: "\e932";
}

.icon-e-52:before {
  content: "\e933";
}

.icon-e-53:before {
  content: "\e934";
}

.icon-e-54:before {
  content: "\e935";
}

.icon-e-55:before {
  content: "\e936";
}

.icon-e-56:before {
  content: "\e937";
}

.icon-e-57:before {
  content: "\e938";
}

.icon-e-58:before {
  content: "\e939";
}

.icon-e-59:before {
  content: "\e93a";
}

.icon-e-60:before {
  content: "\e93b";
}

.icon-e-61:before {
  content: "\e93c";
}

.icon-e-62:before {
  content: "\e93d";
}

.icon-e-63:before {
  content: "\e93e";
}

.icon-e-64:before {
  content: "\e93f";
}

.icon-e-65:before {
  content: "\e940";
}

.icon-e-66:before {
  content: "\e941";
}

.icon-e-67:before {
  content: "\e942";
}

.icon-e-68:before {
  content: "\e943";
}

.icon-e-69:before {
  content: "\e944";
}

.icon-e-70:before {
  content: "\e945";
}

.icon-e-71:before {
  content: "\e946";
}

.icon-e-72:before {
  content: "\e947";
}

.icon-e-73:before {
  content: "\e948";
}

.icon-e-74:before {
  content: "\e949";
}

.icon-e-75:before {
  content: "\e94a";
}

.icon-e-76:before {
  content: "\e94b";
}

.icon-e-77:before {
  content: "\e94c";
}

.icon-e-78:before {
  content: "\e94d";
}

.icon-e-79:before {
  content: "\e94e";
}

.icon-e-80:before {
  content: "\e94f";
}

.icon-e-81:before {
  content: "\e950";
}

.icon-e-82:before {
  content: "\e951";
}

.icon-e-83:before {
  content: "\e952";
}

.icon-e-84:before {
  content: "\e953";
}

.icon-e-85:before {
  content: "\e954";
}

.icon-e-86:before {
  content: "\e955";
}

.icon-e-87:before {
  content: "\e956";
}

.icon-e-88:before {
  content: "\e957";
}

.icon-e-89:before {
  content: "\e958";
}

.icon-e-90:before {
  content: "\e959";
}

.icon-e-91:before {
  content: "\e95a";
}

.icon-e-92:before {
  content: "\e95b";
}

.icon-e-93:before {
  content: "\e95c";
}

.icon-e-94:before {
  content: "\e95d";
}

.icon-e-95:before {
  content: "\e95e";
}

.icon-e-96:before {
  content: "\e95f";
}

.icon-f-01:before {
  content: "\e960";
}

.icon-f-02:before {
  content: "\e961";
}

.icon-f-03:before {
  content: "\e962";
}

.icon-f-04:before {
  content: "\e963";
}

.icon-f-05:before {
  content: "\e964";
}

.icon-f-06:before {
  content: "\e965";
}

.icon-f-07:before {
  content: "\e966";
}

.icon-f-08:before {
  content: "\e967";
}

.icon-f-09:before {
  content: "\e968";
}

.icon-f-10:before {
  content: "\e969";
}

.icon-f-11:before {
  content: "\e96a";
}

.icon-f-12:before {
  content: "\e96b";
}

.icon-f-13:before {
  content: "\e96c";
}

.icon-f-14:before {
  content: "\e96d";
}

.icon-f-15:before {
  content: "\e96e";
}

.icon-f-16:before {
  content: "\e96f";
}

.icon-f-17:before {
  content: "\e970";
}

.icon-f-18:before {
  content: "\e971";
}

.icon-f-19:before {
  content: "\e972";
}

.icon-f-20:before {
  content: "\e973";
}

.icon-f-21:before {
  content: "\e974";
}

.icon-f-22:before {
  content: "\e975";
}

.icon-f-23:before {
  content: "\e976";
}

.icon-f-24:before {
  content: "\e977";
}

.icon-f-25:before {
  content: "\e978";
}

.icon-f-26:before {
  content: "\e979";
}

.icon-f-27:before {
  content: "\e97a";
}

.icon-f-28:before {
  content: "\e97b";
}

.icon-f-29:before {
  content: "\e97c";
}

.icon-f-30:before {
  content: "\e97d";
}

.icon-f-31:before {
  content: "\e97e";
}

.icon-f-32:before {
  content: "\e97f";
}

.icon-f-33:before {
  content: "\e980";
}

.icon-f-34:before {
  content: "\e981";
}

.icon-f-35:before {
  content: "\e982";
}

.icon-f-36:before {
  content: "\e983";
}

.icon-f-37:before {
  content: "\e984";
}

.icon-f-38:before {
  content: "\e985";
}

.icon-f-39:before {
  content: "\e986";
}

.icon-f-40:before {
  content: "\e987";
}

.icon-f-41:before {
  content: "\e988";
}

.icon-f-42:before {
  content: "\e989";
}

.icon-f-43:before {
  content: "\e98a";
}

.icon-f-44:before {
  content: "\e98b";
}

.icon-f-45:before {
  content: "\e98c";
}

.icon-f-46:before {
  content: "\e98d";
}

.icon-f-47:before {
  content: "\e98e";
}

.icon-f-48:before {
  content: "\e98f";
}

.icon-f-49:before {
  content: "\e990";
}

.icon-f-50:before {
  content: "\e991";
}

.icon-f-51:before {
  content: "\e992";
}

.icon-f-52:before {
  content: "\e993";
}

.icon-f-53:before {
  content: "\e994";
}

.icon-f-54:before {
  content: "\e995";
}

.icon-f-55:before {
  content: "\e996";
}

.icon-f-56:before {
  content: "\e997";
}

.icon-f-57:before {
  content: "\e998";
}

.icon-f-58:before {
  content: "\e999";
}

.icon-f-59:before {
  content: "\e99a";
}

.icon-f-60:before {
  content: "\e99b";
}

.icon-f-61:before {
  content: "\e99c";
}

.icon-f-62:before {
  content: "\e99d";
}

.icon-f-63:before {
  content: "\e99e";
}

.icon-f-64:before {
  content: "\e99f";
}

.icon-f-65:before {
  content: "\e9a0";
}

.icon-f-66:before {
  content: "\e9a1";
}

.icon-f-67:before {
  content: "\e9a2";
}

.icon-f-68:before {
  content: "\e9a3";
}

.icon-f-69:before {
  content: "\e9a4";
}

.icon-f-70:before {
  content: "\e9a5";
}

.icon-f-71:before {
  content: "\e9a6";
}

.icon-f-72:before {
  content: "\e9a7";
}

.icon-f-73:before {
  content: "\e9a8";
}

.icon-f-74:before {
  content: "\e9a9";
}

.icon-f-75:before {
  content: "\e9aa";
}

.icon-f-76:before {
  content: "\e9ab";
}

.icon-f-77:before {
  content: "\e9ac";
}

.icon-f-78:before {
  content: "\e9ad";
}

.icon-f-79:before {
  content: "\e9ae";
}

.icon-f-80:before {
  content: "\e9af";
}

.icon-f-81:before {
  content: "\e9b0";
}

.icon-f-82:before {
  content: "\e9b1";
}

.icon-f-83:before {
  content: "\e9b2";
}

.icon-f-84:before {
  content: "\e9b3";
}

.icon-f-85:before {
  content: "\e9b4";
}

.icon-f-86:before {
  content: "\e9b5";
}

.icon-f-87:before {
  content: "\e9b6";
}

.icon-f-88:before {
  content: "\e9b7";
}

.icon-f-89:before {
  content: "\e9b8";
}

.icon-f-90:before {
  content: "\e9b9";
}

.icon-f-91:before {
  content: "\e9ba";
}

.icon-f-92:before {
  content: "\e9bb";
}

.icon-f-93:before {
  content: "\e9bc";
}

.icon-f-94:before {
  content: "\e9bd";
}

.icon-f-95:before {
  content: "\e9be";
}

.icon-f-96:before {
  content: "\e9bf";
}

.icon-g-01:before {
  content: "\e9c0";
}

.icon-g-02:before {
  content: "\e9c1";
}

.icon-g-03:before {
  content: "\e9c2";
}

.icon-g-04:before {
  content: "\e9c3";
}

.icon-g-05:before {
  content: "\e9c4";
}

.icon-g-06:before {
  content: "\e9c5";
}

.icon-g-07:before {
  content: "\e9c6";
}

.icon-g-08:before {
  content: "\e9c7";
}

.icon-g-09:before {
  content: "\e9c8";
}

.icon-g-10:before {
  content: "\e9c9";
}

.icon-g-11:before {
  content: "\e9ca";
}

.icon-g-12:before {
  content: "\e9cb";
}

.icon-g-13:before {
  content: "\e9cc";
}

.icon-g-14:before {
  content: "\e9cd";
}

.icon-g-15:before {
  content: "\e9ce";
}

.icon-g-16:before {
  content: "\e9cf";
}

.icon-g-17:before {
  content: "\e9d0";
}

.icon-g-18:before {
  content: "\e9d1";
}

.icon-g-19:before {
  content: "\e9d2";
}

.icon-g-20:before {
  content: "\e9d3";
}

.icon-g-21:before {
  content: "\e9d4";
}

.icon-g-22:before {
  content: "\e9d5";
}

.icon-g-23:before {
  content: "\e9d6";
}

.icon-g-24:before {
  content: "\e9d7";
}

.icon-g-25:before {
  content: "\e9d8";
}

.icon-g-26:before {
  content: "\e9d9";
}

.icon-g-27:before {
  content: "\e9da";
}

.icon-g-28:before {
  content: "\e9db";
}

.icon-g-29:before {
  content: "\e9dc";
}

.icon-g-30:before {
  content: "\e9dd";
}

.icon-g-31:before {
  content: "\e9de";
}

.icon-g-32:before {
  content: "\e9df";
}

.icon-g-33:before {
  content: "\e9e0";
}

.icon-g-34:before {
  content: "\e9e1";
}

.icon-g-35:before {
  content: "\e9e2";
}

.icon-g-36:before {
  content: "\e9e3";
}

.icon-g-37:before {
  content: "\e9e4";
}

.icon-g-38:before {
  content: "\e9e5";
}

.icon-g-39:before {
  content: "\e9e6";
}

.icon-g-40:before {
  content: "\e9e7";
}

.icon-g-41:before {
  content: "\e9e8";
}

.icon-g-42:before {
  content: "\e9e9";
}

.icon-g-43:before {
  content: "\e9ea";
}

.icon-g-44:before {
  content: "\e9eb";
}

.icon-g-45:before {
  content: "\e9ec";
}

.icon-g-46:before {
  content: "\e9ed";
}

.icon-g-47:before {
  content: "\e9ee";
}

.icon-g-48:before {
  content: "\e9ef";
}

.icon-g-49:before {
  content: "\e9f0";
}

.icon-g-50:before {
  content: "\e9f1";
}

.icon-g-51:before {
  content: "\e9f2";
}

.icon-g-52:before {
  content: "\e9f3";
}

.icon-g-53:before {
  content: "\e9f4";
}

.icon-g-54:before {
  content: "\e9f5";
}

.icon-g-55:before {
  content: "\e9f6";
}

.icon-g-56:before {
  content: "\e9f7";
}

.icon-g-57:before {
  content: "\e9f8";
}

.icon-g-58:before {
  content: "\e9f9";
}

.icon-g-59:before {
  content: "\e9fa";
}

.icon-g-60:before {
  content: "\e9fb";
}

.icon-g-61:before {
  content: "\e9fc";
}

.icon-g-62:before {
  content: "\e9fd";
}

.icon-g-63:before {
  content: "\e9fe";
}

.icon-g-64:before {
  content: "\e9ff";
}

.icon-g-65:before {
  content: "\ea00";
}

.icon-g-66:before {
  content: "\ea01";
}

.icon-g-67:before {
  content: "\ea02";
}

.icon-g-68:before {
  content: "\ea03";
}

.icon-g-69:before {
  content: "\ea04";
}

.icon-g-70:before {
  content: "\ea05";
}

.icon-g-71:before {
  content: "\ea06";
}

.icon-g-72:before {
  content: "\ea07";
}

.icon-g-73:before {
  content: "\ea08";
}

.icon-g-74:before {
  content: "\ea09";
}

.icon-g-75:before {
  content: "\ea0a";
}

.icon-g-76:before {
  content: "\ea0b";
}

.icon-g-77:before {
  content: "\ea0c";
}

.icon-g-78:before {
  content: "\ea0d";
}

.icon-g-79:before {
  content: "\ea0e";
}

.icon-g-80:before {
  content: "\ea0f";
}

.icon-g-81:before {
  content: "\ea10";
}

.icon-g-82:before {
  content: "\ea11";
}

.icon-g-83:before {
  content: "\ea12";
}

.icon-g-84:before {
  content: "\ea13";
}

.icon-g-85:before {
  content: "\ea14";
}

.icon-g-86:before {
  content: "\ea15";
}

.icon-g-87:before {
  content: "\ea16";
}

.icon-g-88:before {
  content: "\ea17";
}

.icon-g-89:before {
  content: "\ea18";
}

.icon-g-90:before {
  content: "\ea19";
}

.icon-g-91:before {
  content: "\ea1a";
}

.icon-g-92:before {
  content: "\ea1b";
}

.icon-g-93:before {
  content: "\ea1c";
}

.icon-g-94:before {
  content: "\ea1d";
}

.icon-g-95:before {
  content: "\ea1e";
}

.icon-g-96:before {
  content: "\ea1f";
}

.icon-h-01:before {
  content: "\ea20";
}

.icon-h-02:before {
  content: "\ea21";
}

.icon-h-03:before {
  content: "\ea22";
}

.icon-h-04:before {
  content: "\ea23";
}

.icon-h-05:before {
  content: "\ea24";
}

.icon-h-06:before {
  content: "\ea25";
}

.icon-h-07:before {
  content: "\ea26";
}

.icon-h-08:before {
  content: "\ea27";
}

.icon-h-09:before {
  content: "\ea28";
}

.icon-h-10:before {
  content: "\ea29";
}

.icon-h-11:before {
  content: "\ea2a";
}

.icon-h-12:before {
  content: "\ea2b";
}

.icon-h-13:before {
  content: "\ea2c";
}

.icon-h-14:before {
  content: "\ea2d";
}

.icon-h-15:before {
  content: "\ea2e";
}

.icon-h-16:before {
  content: "\ea2f";
}

.icon-h-17:before {
  content: "\ea30";
}

.icon-h-18:before {
  content: "\ea31";
}

.icon-h-19:before {
  content: "\ea32";
}

.icon-h-20:before {
  content: "\ea33";
}

.icon-h-21:before {
  content: "\ea34";
}

.icon-h-22:before {
  content: "\ea35";
}

.icon-h-23:before {
  content: "\ea36";
}

.icon-h-24:before {
  content: "\ea37";
}

.icon-h-25:before {
  content: "\ea38";
}

.icon-h-26:before {
  content: "\ea39";
}

.icon-h-27:before {
  content: "\ea3a";
}

.icon-h-28:before {
  content: "\ea3b";
}

.icon-h-29:before {
  content: "\ea3c";
}

.icon-h-30:before {
  content: "\ea3d";
}

.icon-h-31:before {
  content: "\ea3e";
}

.icon-h-32:before {
  content: "\ea3f";
}

.icon-h-33:before {
  content: "\ea40";
}

.icon-h-34:before {
  content: "\ea41";
}

.icon-h-35:before {
  content: "\ea42";
}

.icon-h-36:before {
  content: "\ea43";
}

.icon-h-37:before {
  content: "\ea44";
}

.icon-h-38:before {
  content: "\ea45";
}

.icon-h-39:before {
  content: "\ea46";
}

.icon-h-40:before {
  content: "\ea47";
}

.icon-h-41:before {
  content: "\ea48";
}

.icon-h-42:before {
  content: "\ea49";
}

.icon-h-43:before {
  content: "\ea4a";
}

.icon-h-44:before {
  content: "\ea4b";
}

.icon-h-45:before {
  content: "\ea4c";
}

.icon-h-46:before {
  content: "\ea4d";
}

.icon-h-47:before {
  content: "\ea4e";
}

.icon-h-48:before {
  content: "\ea4f";
}

.icon-h-49:before {
  content: "\ea50";
}

.icon-h-50:before {
  content: "\ea51";
}

.icon-h-57:before {
  content: "\ea58";
}

.icon-Nowishlist .path1:before {
  content: "\eade";
  color: rgb(255, 255, 255);
}

.icon-Nowishlist .path2:before {
  content: "\eadf";
  margin-left: -1em;
  color: rgb(196, 196, 196);
}

.icon-Nowishlist .path3:before {
  content: "\eae0";
  margin-left: -1em;
  color: rgb(25, 25, 25);
}

.icon-n-01:before {
  content: "\eb10";
}

.icon-n-022:before {
  content: "\eb11";
}

.icon-n-03:before {
  content: "\eb12";
}

.icon-n-04:before {
  content: "\eb13";
}

.icon-n-052:before {
  content: "\eb14";
}

.icon-n-062:before {
  content: "\eb15";
}

.icon-n-072:before {
  content: "\eb16";
}

.icon-Vector:before {
  content: "\eb17";
}

.icon-n-08:before {
  content: "\eb18";
}

.icon-tumblr:before {
  content: "\eab9";
}

.icon-tumblr2:before {
  content: "\eaba";
}
