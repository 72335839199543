$default_color: #2879fe;
$default_color2: #191919;
$default_font: 'Darker Grotesque', sans-serif;
$default_font2: $default_font;
/* font icon*/
$default_font_icon: 'wokiee';
/* menu slick slider */
.arrow-location-03 .slick-arrow {
  top: -27px;
}

.arrow-location-03 .slick-prev {
  left: inherit;
}

.arrow-location-03 .slick-next:before, .arrow-location-03 .slick-prev:before {
  color: #777777;
  opacity: 1;
}

.snptwdgt-container .snptwdgt__item .snpt__fauximg {
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: scale(1);
  transform: scale(1);
  backface-visibility: hidden;
}

.hotspot {
  backface-visibility: hidden;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  text-decoration: none;
}

.snptwdgt-container .snptwdgt__item .snpt__fauximg {
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: scale(1);
  transform: scale(1);
  backface-visibility: hidden;
}

.snptwdgt-container .snptwdgt__item:hover .snpt__fauximg {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.snptwdgt-container .snptwdgt__item:hover .widget-hotspot {
  opacity: 1 !important;
}

.snptwdgt-container .snptwdgt__item .snptlinked-item-ico {
  display: none;
}

.snptwdgt-container {
  margin-top: 20px;
}

.snptwdgt-container .snptwdgt__ftr {
  display: none;
}

.hotspot-modal .modal-dialog .prev-chevron,
.hotspot-modal .modal-dialog .next-chevron {
  top: calc(50% - 14px);
}

.insta-shop {
  .widget-hotspot {
    background-color: $default_color;
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 26px;
    color: #ffffff;
    border: 4px solid rgba(255, 255, 255, 1);
    font-family: $default_font2;
    transform: none;
    transition: 0.3s;
    opacity: 0.4;
  }

  .snptwdgt-rtd__fottr {
    display: none !important;
  }
}

.widget-hotspot:hover {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

.hotspot-modal-tm-1 .mobile-modal-header,
.hotspot-modal-tm-1 .modal-backdrop.modal-backdrop--hotspots {
  background: #000000;
  opacity: .5;
}

.hotspot-modal-tm-1 .snpt-modal.hotspot-modal .ico-close {
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.2s linear;
  top: -40px;
}

@media (min-width: 1101px) {
  .hotspot-modal-tm-1 .snpt-modal.hotspot-modal .ico-close {
    right: -30px;
  }
}

@media (max-width: 1100px) {
  .hotspot-modal-tm-1 .snpt-modal.hotspot-modal .ico-close {
    right: -3px;
  }
}

.hotspot-modal-tm-1 .snpt-modal.hotspot-modal .ico-close:hover {
  color: $default_color;
}

.snptico-close:before {
  content: "\ea0f";
  font-family: $default_font_icon;
  color: #fff;
}

.hotspot-modal-tm-1 .snpt-modal.hotspot-modal .modal-content {
  border-radius: 0 !important;
}

.hotspot-modal .modal-dialog .prev-chevron,
.hotspot-modal .modal-dialog .next-chevron {
  font-family: $default_font_icon !important;
  font-size: 20px !important;
}

.hotspot-modal .modal-dialog .prev-chevron:before {
  content: "\e90d";
  color: #fff;
}

.hotspot-modal .modal-dialog .next-chevron:before {
  content: "\e90e";
  color: #fff;
}

.hotspot-modal .modal-dialog .next-chevron:hover:before,
.hotspot-modal .modal-dialog .prev-chevron:hover:before {
  color: $default_color;
}

.hotspot-modal--frontend .product-images-slide img {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.hotspot-modal-tm-1 public-modal > .hotspot-modal .public-hotspot-info-holder .public-hotspot-info .public-hotspot-info__btn-buy.snpt-cta-btn {
  background: $default_color;
  color: #ffffff;
  border-radius: 0 !important;
  padding: 11px 19px 10px;
  font-size: 14px;
  line-height: 1.1;
  font-family: $default_font2;
  font-weight: 500;
  transition: all 0.2s linear;
}

.hotspot-modal .public-hotspot-info-holder .public-hotspot-info .public-hotspot-info__btn-buy.snpt-cta-btn > span.snpt-cta-btn_txt {
  letter-spacing: 0.03em;
}

.hotspot-modal-tm-1 public-modal > .hotspot-modal .public-hotspot-info-holder .public-hotspot-info .public-hotspot-info__btn-buy.snpt-cta-btn:hover {
  background: $default_color2;
  color: #ffffff;
}

.hotspot-modal-tm-1 public-modal > .hotspot-modal .public-hotspot-info-holder .public-hotspot-info .public-hotspot-info__btn-buy.snpt-cta-btn span.snpt-cta-btn_ico {
  display: none;
}

.hotspot-modal .public-hotspot-info-holder .public-hotspot-info .public-hotspot-info__number {
  color: $default_color2;
  font-size: 14px;
}

.hotspot-modal .public-hotspot-info-holder .public-hotspot-info .public-hotspot-info__second-line {
  color: $default_color2;
  font-size: 16px;
}

.hotspot-modal--frontend .public-hotspot--username {
  text-transform: capitalize;
}

.hotspot-modal .public-hotspot-info-holder .public-hotspot-info .public-hotspot-info__second-line {
  height: auto;
}

.hotspot-modal-tm-1 .snpt-modal.hotspot-modal .hotspot {
  background: $default_color;
  color: #ffffff;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 1.7;
}

.hotspot-modal-tm-1 .snpt-modal.hotspot-modal .hotspot:hover {
  background: $default_color2;
}

.hotspot-modal-tm-1 .crd-cta-blck > a {
  display: none;
}

.snpt-modal.hotspot-modal a {
  color: $default_color2;
  cursor: pointer;
  transition: color 0.2s linear;
}

.snpt-modal.hotspot-modal a:hover {
  color: #42d6c2;
}

.snptwdgt-qpr__ftr {
  display: none;
}