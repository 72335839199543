.general-style-widget {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.button-style-widget {
  border-radius: 50%;
  padding: 10px;
  background-color: whitesmoke;
  border: none;
}

.image-style-widget {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.icon-style-widget {
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.widget-chatbox {
  position: relative;
  background: white;
  border-radius: 15px;
  overflow: hidden;
  width: 420px;
  height: 625px;
  z-index: 9999;
}

.close-chatbot {
  border: none;
  color: white;
  height: 40px;
  width: 40px;
  font-size: 1.9rem;
  cursor: pointer;
  border-radius: 50%;
  background: none;
  position: absolute;
  top: 20px;
  right: 10px;
  transition: 0.2s ease;
}

.close-chatbot:hover {
  background: rgba(63, 76, 83, 0.49);
}

.frame-background-style-widget {
  width: 100%;
  height: 100%;

}

.in-frame-background-style-widget {
  width: 100%;
  height: 100%;
  border: none;

}

@media (max-width: 480px) {
  .general-style-widget {
    bottom: 0.10px;
    right: 0.10px;
  }
}
