.heading-category {
  font-size: 14px;
  letter-spacing: 16px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    letter-spacing: 12px;
  }
  @media (max-width: 378px) {
    font-size: 12px;
    letter-spacing: 8px;
  }
}

.heading-category-2 {
  letter-spacing: 8px;
  font-weight: 600;
}

.footer-container {
  font-size: 16px;
}

.assistant {
  display: flex;
  background-color: #fff;
  margin-top: 44px;
  margin-bottom: 44px;
  border: 1px solid #e9e7e7;
  padding: 44px 0px;
  padding-bottom: 64px;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  .assistant-padding {
    width: 100%;
    padding: 0px 120px;
    @media (max-width: 1600px) {
      padding: 0px 44px;
    }
  }

  .bar-body {
    margin: 0px;
    margin-bottom: 72px;
  }
  .assistant-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: relative;
    .assistant-go-back-wrapper {
      position: absolute;
      left: 0px;
      top: 0px;
      align-items: center;
      display: flex;
      gap: 6px;
      cursor: pointer;
      user-select: none;
      &:hover {
        filter: brightness(0.6);
      }
    }
    .assistant-go-back-wrapper img {
      width: 10px;
    }
    .assistant-header-text {
      align-self: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  .assistant-header {
    font-size: 20px;
    color: #122a42;
    margin-bottom: 12px;
    font-weight: 600;
    text-align: center;
  }
  .dimensions-inputs-row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    .dimension-input-container {
      background-color: #f7f7f9;
      padding: 16px 44px;
      width: 240px;
      height: 320px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .dimension-input-name {
        color: #777;
        font-weight: 400;
        font-size: 16px;
      }
      .dimension-input-number {
        color: black;
        font-weight: 300;
        background: transparent;
        border: 0px solid white;
        text-align: center;
        font-size: 64px;
        width: 100%;
        outline: none;
        // &::-webkit-outer-spin-button {
        //   -webkit-appearance: none;
        //   margin: 0;
        // }
        // &::-webkit-inner-spin-button {
        //   -webkit-appearance: none;
        //   margin: 0;
        // }
        // &[type=number] {
        //   -moz-appearance: textfield;
        // }
      }
      .dimension-input-unit {
        color: #777;
        font-weight: 300;
        font-size: 16px;
      }
    }
    .inputs-row-separator {
      margin: 20px 44px 44px 44px;
      font-size: 88px;
      color: #e5e5e5;
      font-weight: 300;
    }
  }
  .sanmarino-logo-text {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: #e94546;
    font-weight: 600;
    font-size: 16px;
  }
  .sanmarino-logo-text span {
    color: #122a42;
  }
  .assistant-subheader {
    font-size: 16px;
    text-align: center;
    color: #777777;
    width: 70%;
    margin-bottom: 64px;
  }
  .assistant-header-wrapper .assistant-subheader {
    width: unset;
  }
  .assistant-center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .center-content-title {
      color: black;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 24px;
    }
    .content-options-row {
      display: flex;
      justify-content: center;
      gap: 24px;
      flex-wrap: wrap;
      padding: 0px 24px;
      margin-bottom: 44px;
      .option-wrapper {
        width: 240px;
        height: 320px;
        border-radius: 4px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          z-index: 0;
          border-radius: 4px;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.1) 100%
          );
        }
        .option-header-wrapper {
          display: flex;
          position: relative;
          z-index: 1;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          padding: 16px 24px;
          .option-header-step {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 4px;
          }
          .option-header-step-number {
            font-size: 24px;
            font-weight: 600;
          }
        }
      }
      .selectable-option-wrapper {
        cursor: pointer;
        transition: all 0.3s ease-out;
        margin-top: 8px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
        margin-bottom: 0px;
      }
      .selectable-option-wrapper:hover {
        margin-top: 6px;
        margin-bottom: 2px;
        box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.2);
      }
      .selectable-option-wrapper::after {
        content: "";
        position: absolute;
        width: calc(100% + 16px);
        height: calc(100%);
        border-radius: 10px;
        background-color: transparent;
        border: 2px solid #d8d8d800;
        top: 0px;
        left: -8px;
        transition: all 0.2s ease-out 0.1s;
      }
      .selected-option {
        margin-top: 0px;
        margin-bottom: 8px;
        box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.4);
      }
      .selected-option:hover {
        box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.4);
        margin-top: 0px;
        margin-bottom: 8px;
      }
      .selected-option::after {
        content: "";
        position: absolute;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        border-radius: 10px;
        background-color: transparent;
        border: 2px solid #2bdf9a;
        top: -8px;
        left: -8px;
      }
      .step-description-wrapper {
        padding: 24px;
        position: relative;
        z-index: 1;
      }
      .step-description {
        font-size: 20px;
        text-align: center;
      }
      .step-description-title {
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 24px;
      }
    }
    .assistant-arrow-btn {
      padding: 16px 44px 18px 44px;
      line-height: normal;
      font-weight: 600;
      height: max-content;
      display: flex;
      align-items: center;
      color: white;
      user-select: none;
      cursor: pointer;
      transition: 0.15s;
    }
    .assistant-arrow-btn-disabled {
      background-color: #f7f8fa;
      color: #ccc;
      cursor: unset;
    }
    .assistant-arrow-btn-disabled img {
      filter: brightness(0.8);
    }
    .assistant-arrow-btn img {
      margin-top: 2px;
      margin-right: -12px;
      margin-left: 12px;
    }
    // .icon-arrow-right {
    //   background-image: url('assets/images/assistant/icons/arrow-right.png');
    //   background-position: center;
    //   background-size: auto;
    //   width: 18px;
    //   height: 16px;
    //   margin: 0px;
    //   background-color: aqua;
    // }
  }
  .result-container-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .result-information-column {
      position: relative;
      flex-basis: 100%;
      padding-right: 0px;
      display: flex;
      .result-information-wrapper {
        position: sticky;
        display: flex;
        flex-direction: column;
        padding-right: 44px;
        flex-basis: 100%;
        top: 80px;
        left: 0px;
        height: fit-content;
        .result-information-header-wrapper {
          margin-top: 24px;
          margin-bottom: 44px;
          display: flex;
          flex-direction: column;
          .result-information-title {
            font-size: 20px;
            color: #122a42;
            margin-bottom: 12px;
            font-weight: 600;
          }
          .result-information-subtitle {
            font-size: medium;
            color: #777777;
          }
        }
        .result-image-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #e9e7e7;
          width: 100%;
          min-height: 240px;
          border-radius: 8px;
        }
        .result-image-footer {
          margin-top: 12px;
          color: #777 !important;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    
    .result-cart-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      padding-bottom: 0px;
      border-left: 1px solid #e9e7e7;
      flex-basis: 100%;
      .result-cart-header-wrapper {
        width: 100%;
        padding-left: 8px;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        color: #122a42;
        margin-bottom: 20px;
        @media (max-width: 1024px) {
          padding-left: 20px;
          margin-top: 44px;
        }
      }
      .result-product-item-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 24px 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #e9e7e7;
        position: relative;
        .product-options-container {
          position: absolute;
          top: 16px;
          right: 16px;
          // transform: translate(0px, -50%);
          // display: none;
          display: flex;
          align-items: center;
          // background-color: #f2f2f2;
          border-radius: 4px;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.3s;
          .product-options-button {
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: #e9e7e7;
            border-radius: 4px;
            min-width: 32px;
            height: 32px;
            img {
              width: 16px;
              height: 16px;
            }
            &:hover {
              background-color: #f2f2f2;
            }
            &.open {
              background-color: #e9e7e7;
              margin-left: 4px;
            }
          }
          &.open {
            background-color: #f2f2f2;
            opacity: 1;
          }
          .product-options-tray {
            transition: 0.3s;
            overflow: hidden;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 16px;
            width: 0px;
            box-sizing: border-box;
            &.open {
              width: 120px;
              padding: 8px 12px;
              height: 40px;
            }
          }
          // &:hover .product-options-tray {
          //   // min-width: max-content;
          //   width: 120px;
          //   padding: 8px 12px;
          //   transition-delay: 0s;
          // }
        }
        &:hover .product-options-container {
          // display: flex;
          opacity: 1;
        }
        .product-image-container {
          border-radius: 4px;
          height: 80px;
          width: 80px;
          flex-shrink: 0;
          background-color: #e9e7e7;
          display: flex;
          align-items: center;
          justify-content: center;
          object-fit: cover;
          overflow: hidden;
          margin-right: 24px;
        }
        .product-info-col {
          flex-basis: 100%;
          display: flex;
          flex-direction: column;
          .product-name {
            color: black;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 4px;
          }
          .product-item-type-info {
            font-size: 14px;
            color: #777;
            font-weight: 400;
          }
        }
        .product-quantity {
          width: max-content;
          color: #777;
          font-size: 14px;
          font-weight: 400;
          text-align: right;
          margin-right: 44px;
          flex-shrink: 0;
        }
        .product-total-price {
          color: black;
          font-size: 16px;
          font-weight: 600;
          flex-shrink: 0;
          width: max-content;
        }
      }
      .cart-footer {
        display: flex;
        flex-direction: column;
        padding: 24px 16px;
        width: 100%;
        .cart-footer-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: black;
          font-size: 16px;
          font-weight: 600;
          flex-basis: 100%;
          margin-bottom: 24px;
        }
        .cart-primary-button {
          color: white;
          font-size: 16px;
          font-weight: 600;
          flex-basis: 100%;
          padding-top: 16px;
          padding-bottom: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-bottom: 24px;
          line-height: unset;
        }
        .cart-secondary-button {
          color: #777;
          background-color: #e9e7e7;
          font-size: 16px;
          font-weight: 600;
          flex-basis: 100%;
          padding-top: 16px;
          padding-bottom: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          &:hover {
            background-color: #d8d8d8;
            color: #666;
          }
        }
      }
    }
  }
}
.cart-undo-changes {
  color: #777;
  background-color: #e9e7e7;
  font-size: 16px;
  font-weight: 600;
  width: max-content;
  padding-top: 16px;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  &:hover {
    background-color: #d8d8d8;
    color: #666;
  }
}

.estilosWidgetOne {
  font-weight: bold;
  font-size: large;
}