.checkout-item {
  h4 {
    position: relative;
    padding-bottom: 10px !important;
    margin-bottom: 30px;

    @media #{$sm-device} {
      margin-bottom: 16px;
    }

    &:before {
      background-color: $base-dark-color;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 50px;
    }
  }
}

.order-details-area-wrap {
  border: 2px solid #e7e7e7;
  padding: 50px;
  margin-top: 50px;
  color: #8d8d8d;
  font-size: 13px;

  @media #{$xs-device} {
    padding: 30px 20px;
  }

  @media #{$sm-device,$md-device} {
    margin-top: 30px;
  }
  .order-details-table {
    font-size: 13px;
    line-height: 1;
    white-space: nowrap;

    .custom-control-label,
    th {
      font-size: 13px;
      font-weight: 400;
    }

    .table {
      thead {
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 27px;

        th {
          margin-bottom: 27px;
        }
      }

      tbody {
        border-bottom: 1px solid #e7e7e7;

        .cart-item:first-child {
          td {
            padding-top: 30px;
          }
        }

        .cart-item:last-child {
          td {
            padding-bottom: 30px;
          }
        }
      }

      tfoot {
        .shipping {
          .shipping-method {
            list-style: none;
            padding-left: 0;
            text-align: left;

            li {
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
              }

              input {
                margin-top: 0 !important;
              }
            }
          }
        }

        .total-amount {
          font-size: 20px;
        }

        tr {
          border-bottom: 1px solid #e7e7e7;
        }
      }

      tr {
        td,
        th {
          padding: 15px 0;
          color: $black;

          &:last-child {
            text-align: right;
          }
        }
      }
    }
    .product-thumbnail img {
      border-radius: 4px;
      margin-right: 12px;
      height: 80px;
    }
    .product-title {
      font-size: 16px;
      .product-quantity {
        font-weight: 300;
        margin-left: 12px;
        color: #8d8d8d;
        font-size: 14px;
      }
    }
  }

  .order-details-footer {
    margin-top: 16px;

    .custom-control {
      .custom-control-label {
        font-size: 12px;

        &:before {
          top: 4px;
        }

        &:after {
          top: 3px;
        }
      }
    }
  }
}
.progressbar-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 0px 10%;
}
.bar-body {
  height: 2px;
  width: 100%;
  background-color: #bdbdbd;
  position: relative;
  margin: 44px 0 88px 0;

  .active-node {
    .bar-node {
      background-color: rgb(43, 223, 154) !important;
    }

    .bar-node-text {
      color: rgb(43, 223, 154) !important;
      font-weight: 600;
    }
  }

  .bar-node-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    .bar-node {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      position: relative;
      background-color: #bdbdbd;
      display: flex;
      justify-content: center;

      .bar-node-text {
        color: #bdbdbd;
        position: absolute;
        text-align: center;
        white-space: nowrap;
        top: 16px;
      }
    }
  }
}

.tickets-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    justify-content: start;
  }
}

.sticky-container {
  position: sticky;
  top: 100px;
  width: 40%;
  height: max-content;
  padding-top: 24px;
  border: 1px solid #e9e7e7;
  gap: 24px;
  @media (max-width: 768px) {
    width: 100%;
  }
  .spaciated-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    align-items: center;

    &:last-child {
      font-size: 20px;
      color: black;
      font-weight: 300;
    }

    .ticket-resume-header {
      font-size: 20px;
      color: black;
      font-weight: 500;
    }
  }
  .ticket-resume-separator {
    width: 100%;
    height: 1px;
    margin: 24px 0;
    background-color: #e9e7e7;
  }
  .paybutton-wrapper {
    display: flex;
    justify-content: center;
    padding: 24px;
    flex-direction: column;
    background-color: #e9e7e7;
    margin-top: 24px;
    .payment-successful {
      font-weight: 500;
      color:rgb(43, 223, 154);
      font-size: 16px;
    }
    .back-to-home {
      padding: 8px 12px;
      border-radius: 4px;
      background-color: $default_color;
      width: max-content;
      color: white;
      margin: 0px;
    }
  }
}
.error-page-cta {
  color: white;
  font-size: 20px;
  padding: 12px 24px;
  margin: 32px 0px;
  background-color: $default_color;
  border-radius: 4px;
  line-height: 24px;
}
.order-resume-main-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e9e7e7;
  padding: 24px;
  min-width: 50%;
  max-width: 55%;
  @media (max-width: 768px) {
    max-width: unset;
    width: 100%;
  }
  .order-title {
    display: flex;
    flex-direction: column;
    .order-title {
      font-size: 16px;
      color: rgb(124, 124, 126);
      font-weight: 300;
      line-height: 16px;
    }
  }
  .products-frame {
    display: flex;
    flex-direction: column;

    gap: 16px;
    .product-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: max-content;
      .product-item-thumb {
        max-width: 54px;
        margin-right: 12px;
        border-radius: 2px;
        gap: 6px;
      }
      .product-item-title-group {
        display: flex;
        flex-direction: column;
        .product-item-sku {
          font-size: 12px;
          color: rgb(163, 163, 163);
          line-height: 12px;
          text-transform: uppercase;
          font-weight: 300;
        }
        .product-item-title {
          font-size: 16px;
          line-height: 16px;
          color: black;
          font-weight: 500;
          margin-bottom: 4px;
        }
        .product-item-qty {
          font-size: 14px;
          line-height: 14px;
          color: rgb(124, 124, 126);
        }
      }
      .product-item-price {
        font-weight: 300;
        color: black;
        font-size: 20px;
      }
    }
  }
  .order-resume-separator {
    width: 100%;
    background-color: #e9e7e7;
    height: 1px;
    margin: 12px 0px 24px 0px;
  }
  .dispatch-type-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .dispatch-price {
      font-weight: 300;
      color: black;
      font-size: 20px;
      text-align: right;
    }
  }
}

.elDivDeMP button {
  background-color: $default_color;
}
